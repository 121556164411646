exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-js": () => import("./../../../src/pages/layout.js" /* webpackChunkName: "component---src-pages-layout-js" */),
  "component---src-pages-projects-bookdex-index-js": () => import("./../../../src/pages/projects/bookdex/index.js" /* webpackChunkName: "component---src-pages-projects-bookdex-index-js" */),
  "component---src-pages-projects-bookdex-layout-js": () => import("./../../../src/pages/projects/bookdex/layout.js" /* webpackChunkName: "component---src-pages-projects-bookdex-layout-js" */),
  "component---src-pages-projects-bookdex-privacy-js": () => import("./../../../src/pages/projects/bookdex/privacy.js" /* webpackChunkName: "component---src-pages-projects-bookdex-privacy-js" */),
  "component---src-pages-projects-tales-of-the-seas-index-js": () => import("./../../../src/pages/projects/tales-of-the-seas/index.js" /* webpackChunkName: "component---src-pages-projects-tales-of-the-seas-index-js" */),
  "component---src-pages-projects-tales-of-the-seas-layout-js": () => import("./../../../src/pages/projects/tales-of-the-seas/layout.js" /* webpackChunkName: "component---src-pages-projects-tales-of-the-seas-layout-js" */),
  "component---src-pages-projects-tales-of-the-seas-privacy-js": () => import("./../../../src/pages/projects/tales-of-the-seas/privacy.js" /* webpackChunkName: "component---src-pages-projects-tales-of-the-seas-privacy-js" */),
  "component---src-pages-projects-the-rose-and-the-child-index-js": () => import("./../../../src/pages/projects/the-rose-and-the-child/index.js" /* webpackChunkName: "component---src-pages-projects-the-rose-and-the-child-index-js" */),
  "component---src-pages-projects-the-rose-and-the-child-layout-js": () => import("./../../../src/pages/projects/the-rose-and-the-child/layout.js" /* webpackChunkName: "component---src-pages-projects-the-rose-and-the-child-layout-js" */),
  "component---src-pages-projects-the-rose-and-the-child-privacy-js": () => import("./../../../src/pages/projects/the-rose-and-the-child/privacy.js" /* webpackChunkName: "component---src-pages-projects-the-rose-and-the-child-privacy-js" */)
}

